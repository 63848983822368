import {
  Banner,
  Box,
  CardCentered,
  Container,
  Heading,
  Helmet,
  IconFosterCare,
  IconGuardianship,
  IconHousingAlt,
  Interfaces,
  Layout,
  LinkList,
  PreStyledComponents,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"

import ChildProtectionPolicyTile from "../../../components/child-youth-and-family/ChildProtectionPolicyTile"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { GridWrapper } = PreStyledComponents
const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    childProtectionPolicyStatement: Interfaces.FileProps
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "fostercare") {
    return <IconFosterCare color={color} height={height} />
  }
  if (type === "housingalt") {
    return <IconHousingAlt color={color} height={height} />
  }
  if (type === "guardianship") {
    return <IconGuardianship color={color} height={height} />
  }
  return undefined
}

const bannerBreadcrumbs = [routesObject.home, routesObject.service]

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    childProtectionPolicyStatement
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Child, youth and family services | ${siteMetadata.title}`}
        description="Our foster care, residential care and after care services provide a safe and supportive environment where they can heal and grow."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout theme={youthFull}>
        <Banner
          title="Child, youth and family"
          subTitle="Our foster care, residential care and after care services provide a safe and supportive environment where they can heal and grow."
          image={bannerImage}
          breadcrumbs={bannerBreadcrumbs}
        />
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <Heading size={2} className="color-lwb-theme-medium tc">
                  Our services
                </Heading>
                <GridWrapper className="mt4 flex-ns flex-wrap-ns">
                  <CardCentered
                    href="https://www.lwb.org.au/foster-care/"
                    iconHeight="60"
                    icon="fostercare"
                    title="Foster and kinship care"
                    copy="For children who need time away from their families, foster carers provide a safe, supportive home where they can heal and grow."
                    selectIcon={iconSelector}
                    showReadMore
                  />
                  <CardCentered
                    href="/services/child-youth-and-family/residential-care/"
                    iconHeight="60"
                    icon="housingalt"
                    title="Residential care"
                    copy="Residential care provides homes for young people who are temporarily unable to live with family or foster carers."
                    showReadMore
                    selectIcon={iconSelector}
                  />
                  <CardCentered
                    href="/services/child-youth-and-family/after-care/"
                    iconHeight="60"
                    icon="guardianship"
                    title="After care"
                    copy="For young people who are leaving care, after care provides the support they need to build independent lives."
                    showReadMore
                    selectIcon={iconSelector}
                  />
                </GridWrapper>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box>
              <Heading
                size={3}
                topMargin="none"
                sectionHeading
                className="tc-ns"
              >
                Other Services
              </Heading>
              <LinkList
                links={[
                  {
                    label:
                      "Home Interaction Program for Parents and Youngsters (HIPPY)",
                    to: "/services/child-youth-and-family/hippy-home-interaction-program-for-parents-and-youngsters/"
                  },
                  {
                    label: "Multisystemic Therapy Training (MST)",
                    to: "/services/child-youth-and-family/mst-training/"
                  },
                  {
                    label: "Youth Advocate Program (YAP)",
                    to: "/services/child-youth-and-family/youth-advocate-program/"
                  },
                  {
                    label: "Youth justice",
                    to: "/services/child-youth-and-family/youth-justice/"
                  }
                ]}
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>
                  Our approach - new directions in care
                </Heading>
                <p>
                  To support the best possible outcomes for children and young
                  people in out of home care, we are implementing an evidence
                  informed, therapeutic model of care that is grounded by the
                  six principles of CARE: Creating Conditions for Change.
                  It&apos;s through this approach that we are changing lives and
                  delivering new directions in care.
                </p>
                <ReadMoreLink
                  href="/our-approach/child-youth-and-family/"
                  className="mt3"
                  rooted
                />
              </Box>
              <Box className="w-100 w-50-m w-third-l mt4 mt0-l center-m">
                <ChildProtectionPolicyTile
                  policyStatementUrl={
                    childProtectionPolicyStatement.edges[0].node.publicURL
                  }
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/img-index-banner2.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    childProtectionPolicyStatement: allFile(
      filter: { name: { eq: "Child-Protection-Policy-Statement" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

export default IndexPage
